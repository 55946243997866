import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { getAxios, postAxios } from "../Axios";
import request from "../Requests";

const LoginContext = React.createContext({
    isLoggedIn: false,
    setIsLoggedIn: () => { },
    onLogout: () => { },
    onLogin: (email, password) => { },
    whoAmi: () => { },
    storeDetails:[]
})
const LoginContextProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [storeDetails, setStoreDetails] = useState('')
    const loginHandler = async (username, password) => {
        const data = new FormData();

        data.append('username', username);
        data.append('password', password);
        data.append('json', '1');
        const res = await postAxios(request.login_user, data)
        return res
    }

    const logoutHandler = async () => {
        const res = await getAxios(request.logoff_user)
        if (res.data.Message == 'Logged off successfully!') {
            whoAmi()
        }
    }
    const whoAmi = useCallback(() => {
        const toastId=toast.loading("Please wait...",{
            toastId:"whoAmIToast"
        })
        getAxios(request.who_am_i).then((res)=>{
            res && toast.dismiss(toastId)
            setIsLoggedIn(!!res.data.loggedin)
            setStoreDetails(res?.data)
        })
    },[])

    const contextValue = {
        isLoggedIn: isLoggedIn,
        setIsLoggedIn: setIsLoggedIn,
        onLogin: loginHandler,
        onLogout: logoutHandler,
        whoAmi: whoAmi,
        storeDetails: storeDetails
    }
    return (
        <LoginContext.Provider value={contextValue}>
            {children}
        </LoginContext.Provider>
    )
}

export default LoginContext
export { LoginContextProvider }