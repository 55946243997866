import React from 'react'
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

const StoreNotFound = ({ show = false, setShow, btnAction }) => {

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>No Store found</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You will be logged out automatically because no store has been found.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={btnAction}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default StoreNotFound