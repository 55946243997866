import React, { useContext, useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { postAxios } from '../../Axios'
import request from '../../Requests'
import LoginContext from '../../store/login-context'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Login() {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState("")
    const setUserNameState = (e) => {
        setUsername(e.target.value)
    }
    const setPasswordState = (e) => {
        setPassword(e.target.value)
    }
    function simulateNetworkRequest() {
        return new Promise((resolve) => setTimeout(resolve, 2000));
    }
    const loginCtx = useContext(LoginContext)
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (isLoading) {
            simulateNetworkRequest().then(() => {
                setLoading(false);
            });
        }
    }, [isLoading]);
    const loginAction = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (!!username.trim().length && !!password.trim().length) {
            loginCtx.onLogin(username, password).then((res) => {
                if (res.data.apiresponse.status == 'OK') {
                    setUsername('')
                    setPassword('')
                    setLoading(false)

                } else {
                    setLoading(false)
                    toast.error('Wrong Username/Password', {
                        toastId: 'loginError',
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }).then(() => loginCtx.whoAmi().then(() => {
                // console.log('whoami is running')
            }))
        } else {
            setLoading(false)
            toast.error('Please enter the username & password', {
                toastId: 'loginError',
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    return (
        <div className='d-flex flex-column align-items-center justify-content-center h-100'>
            <ToastContainer />
            <img src="haatnow-logo.png" alt="" style={{
                width: 'min(140px,25%)',
                marginBottom: '20px'
            }} />
            <h1 style={{
                fontFamily: 'Muli,sans-serif',
                fontWeight: '600',
                color: '#3C424F',
                marginBottom: '16px'
            }}>
                Point Of Sale
            </h1>
            <form className='
            p-4 border 
            border-success-subtle'
                style={{
                    width: 'min(768px,95%)'
                }}
                onSubmit={loginAction}
            >
                <div className="mb-3">
                    <label htmlFor="operator_email" className="form-label">Username</label>
                    <input type="text" className="form-control" id="operator_email" aria-describedby="emailHelp" onChange={setUserNameState} value={username} />
                    <div id="emailHelp" className="form-text d-none">We'll never share your email with anyone else.</div>
                </div>
                <div className="mb-3 position-relative">
                    <label htmlFor="operator_login_password" className="form-label">Password</label>
                    <input type={showPassword ? "text" : "password"} className="form-control" id="operator_login_password" onChange={setPasswordState} value={password} />
                    <div className='position-absolute translate-middle end-0 d-flex align-items-center justify-content-end' style={{ top: '73%', fontSize: '20px' }}>
                        {!showPassword && <AiOutlineEye role='button' onClick={() => setShowPassword(!showPassword)} />}
                        {showPassword && <AiOutlineEyeInvisible role='button' onClick={() => setShowPassword(!showPassword)} />}
                    </div>
                </div>
                <div className="mb-3 form-check d-none">
                    <input type="checkbox" className="form-check-input" id="operator_checkbox" />
                    <label className="form-check-label" htmlFor="operator_checkbox">Check me out</label>
                </div>
                <Button
                    variant="outline-success"
                    type='submit'
                    disabled={isLoading}
                >
                    {isLoading ? 'Loading…' : 'Login'}
                </Button>
            </form>
        </div>
    )
}

export default Login