import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";

function NavDropdownMenu({ items }) {
    const [activeTab, setActiveTab] = useState('/')
    const handleSelect = (e) => {
        setActiveTab(e)
    }
    return (
        <Nav variant="pills" className='posNavDropdown container-lg pt-4 px-2' activeKey={activeTab} onSelect={handleSelect}>
            {/* <Nav.Item>
          <Nav.Link eventKey="1" href="#/home">
            NavLink 1 content
          </Nav.Link>
        </Nav.Item> */}
            {
                !!items.length && (
                    items.map((item, idx) => {
                        return (
                            <Nav.Item key={idx}>
                                {!item?.dropDown && (
                                    <Nav.Link as={Link} eventKey={item.to} title={item.name} to={item.to}>

                                        {item.name}
                                    </Nav.Link>)}
                                {item?.dropDown && (
                                    <NavDropdown  title={item.name} id="nav-dropdown">
                                        {
                                            item.dropDown.map((item, index) => {
                                                return (
                                                    <NavDropdown.Item as={Link} to={item.to} eventKey={item.to} key={index} >{item.name}</NavDropdown.Item>
                                                )
                                            })
                                        }
                                        {/* <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="4.2">Another action</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="4.3">Something else here</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item eventKey="4.4">Separated link</NavDropdown.Item> */}
                                    </NavDropdown>
                                )}
                            </Nav.Item>
                        )
                    }))
            }
        </Nav>
    );
}

const MoreOptionsMenu = ({ name, scroll, backdrop, items }) => {
    const [showMoreOptions, setShowMoreOptions] = useState(false);

    const handleClose = () => setShowMoreOptions(false);
    const toggleShowMoreOptions = () => setShowMoreOptions((s) => !s);

    return (
        <>
            <NavDropdownMenu items={items} className='hello' />
            <Button variant="secondary" onClick={toggleShowMoreOptions} className="my-2 d-none">
                {name}
            </Button>
            <Offcanvas show={showMoreOptions} onHide={handleClose} name={name} scroll={scroll} backdrop={backdrop}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    Some text as placeholder. In real life you can have the elements you
                    have chosen. Like, text, images, lists, etc.
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default MoreOptionsMenu