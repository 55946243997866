import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import LoginContext from '../../../store/login-context'
import StoreNotFound from '../StoreNotFound'

function Header() {
    const [showStoreNotFound, setShowStoreNotFound] = useState(false);

    const loginCtx = useContext(LoginContext)

    useEffect(() => {
        loginCtx.whoAmi()
        console.log(loginCtx.storeDetails)
        if (!loginCtx.storeDetails.stores) {
            setShowStoreNotFound(true)
            console.log('store not found')
        }
    }, [])


    return (
        <header className='border border-success' style={{
            background: 'rgba(0,0,0,0.1)'
        }}>
            <StoreNotFound show={showStoreNotFound} setShow={setShowStoreNotFound} btnAction={loginCtx.onLogout} />
            <div className="px-2 px-md-3 px-lg-5">
                <div className="row py-2 mx-auto" style={{ maxWidth: '1400px' }}>
                    <div className="d-flex align-items-center" style={{ width: 'min(100px,20%)', flex: "0 0 auto" }}>
                        <img src="haatnow-logo.png" alt="" className='w-100' />
                    </div>
                    <div className='col'>
                        <div className='d-flex align-items-center h-100'>
                            <div className="row col">
                                <div className="col-6 col-md-5 order-1">
                                    <div className="h-100 row align-items-center justify-content-center px-2 px-lg-5">
                                        <div className="col-12 text-start">
                                            <div>
                                                <b>Operator Name : </b>
                                                <span>{loginCtx.storeDetails?.userinfo?.name}</span>
                                                <span>[{loginCtx.storeDetails?.userinfo?.party_id}]</span>
                                            </div>
                                            {loginCtx.storeDetails?.stores && (
                                                <div>
                                                    <b>Store Name : </b>
                                                    <span>{loginCtx.storeDetails?.stores[0]?.store_name}</span>
                                                    <span>[{loginCtx.storeDetails?.stores[0]?.product_store_id}]</span>
                                                </div>)
                                            }
                                        </div>
                                    </div>
                                </div>
                                <h1
                                    className='m-0 col align-self-end order-3 order-md-2 text-start pt-3 pt-md-0'
                                    style={{
                                        fontFamily: 'Muli,sans-serif',
                                        fontWeight: '600',
                                        color: '#3C424F',
                                    }}>
                                    Point Of Sale
                                </h1>
                                <div className="col-6 col-md-3 order-2 order-md-2 text-end">
                                    <div>

                                        <img
                                            className='mb-2'
                                            src={loginCtx.storeDetails?.userinfo?.photo_url_thumb}
                                            alt="Store Icon"
                                            style={{
                                                width: '55px'
                                            }} />
                                        <p><b>User Login ID: </b><span>{loginCtx.storeDetails?.userinfo?.user_login_id}</span></p>
                                    </div>
                                    <Button variant="outline-success" onClick={loginCtx.onLogout}>Logout</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header