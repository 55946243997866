import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getAxios } from '../../../Axios'
import request from '../../../Requests'
import PaginationCustom from '../../Commons/PaginationCustom'

function DeliveryAgents() {
    const [trackOrderData, setTrackOrderData] = useState([])
    const [activePage, setActivePage] = useState('1')
    useEffect(() => {
        const loader = toast.loading('Please wait...', {
            toastId: 'loader'
        })

        getAxios(`${request.delivery_agents}jtStartIndex=${activePage-1}&jtPageSize=30`)
            .then((res) => {
                setTrackOrderData(res.data)
                toast.dismiss(loader)
            }).catch(()=>{
                alert("Error in loading")
                toast.dismiss(loader)
            })
    }, [])

    const OrderTable = ({ value, idx }) => {
        console.log('value is', value)
        return (
            <tr>
                <td>{idx + 1}</td>
                <td>
                    <img src={value.photo_url_thumb} alt='Image not present' style={{
                        width: '100px'
                    }} />
                </td>
                <td>
                    <b>Full Name : </b><span>{value.full_name}</span>
                    <br />
                    <b>User Login ID : </b><span>{value.user_login_id}</span>
                    <br />
                    <b>User Party ID : </b><span>{value.party_id}</span>
                </td>
                <td>{value.created_date}</td>
                <td>{value.agent_status}</td>
            </tr>
        )
    }
    return (
        <div className='px-5 py-4' style={{ overflow: 'hidden' }}>
            <h1 style={{ fontSize: '30px' }}>Track Previous Orders</h1>
            <Table striped bordered hover className='mb-3' style={{
                verticalAlign: 'middle',
                textAlign: 'center'
            }}>
                <thead>
                    <tr>
                        <th>Sno.</th>
                        <th>Image</th>
                        <th>User Details</th>
                        <th>Date Created</th>
                        <th>Agent Status</th>
                    </tr>
                </thead>
                <tbody>
                    {!!trackOrderData?.Records && trackOrderData?.Records?.map((value, index) => {
                        return (
                            <OrderTable key={index} value={value} idx={index} />
                        )
                    })}
                </tbody>
            </Table>
            <PaginationCustom activePage={activePage} setActivePage={setActivePage} totalRecords={trackOrderData.TotalRecordCount} />
        </div>
    )
}

export default DeliveryAgents