import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";

const PlaceOrderModal = ({ show, setShow, successfullyData, setSuccessfullyData, emptyCart = false }) => {
    const handleClose = () => {
        setShow(false)
        setSuccessfullyData([])
    };
    const handleShow = () => setShow(true);
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Order</Modal.Title>
            </Modal.Header>
            {
                !emptyCart &&
                <Modal.Body>Your Order Placed Successfully and order id is {successfullyData.txn_id}</Modal.Body>
            }
            {
                emptyCart &&
                <Modal.Body>Your Cart is empty</Modal.Body>
            }
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Okay
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PlaceOrderModal