import { Children, useContext, useEffect, useState } from "react";
import { HashRouter, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { Routes } from "react-router-dom";
import { getAxios } from "./Axios";
import Login from "./Components/Login";
import request from "./Requests";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginContext from "./store/login-context";
import Home from "./Components/Home";
import OrdersInQueue from "./Components/Home/OrdersInQueue";
import Dashboard from "./Components/Home/Dashboard";
import PaymentGatewayTransactions from "./Components/Home/PaymentGatewayTransactions";
import OrderTracking from "./Components/Home/OrderTracking";
import DeliveryAgents from "./Components/Home/DeliveryAgents";


function App() {

  const loginCtx = useContext(LoginContext)

  useEffect(() => {
    loginCtx.whoAmi()
  }, [])

  return (
    <div style={{ height: '100vh', overflowY: 'scroll' }}>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Navigate to={!!loginCtx.isLoggedIn ? "/home" : "/login"} />} />
        <Route path="/home" element={!!loginCtx.isLoggedIn ? <Home /> : <Navigate to={'/login'} />}>
          <Route index={true} element={<Dashboard />} />
          <Route path="orders_in_queue" element={<OrdersInQueue />} />
          <Route path="payment_gateway_transactions" element={<PaymentGatewayTransactions />} />
          <Route path="list_of_delivery_agents" element={<DeliveryAgents />} />
          <Route path="order_tracking" element={<OrderTracking />} />
        </Route>
        <Route path="/login" element={!loginCtx.isLoggedIn ? <Login /> : <Navigate to={'/home'} />} />
      </Routes>
    </div>
  );
}

export default App;
