import React, { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap';

const PaginationCustom = ({activePage, setActivePage, totalRecords}) => {
    const [pageNums, setPageNums] = useState([]);
    useEffect(()=>{
        const items=[]
        for (let number = 1; number <= totalRecords / 10; number++) {
            items.push(number)
        }
    console.log(items,"These are the items",totalRecords)
        setPageNums([...items])
    },[totalRecords])
    const paginationClick = (e) => {
        setActivePage(e.target.childNodes[0].wholeText)
    }
    return (
        <Pagination className='justify-content-center orderDetails' style={{ flexWrap: 'wrap' }}>
            {!!pageNums.length && (
                pageNums.map((number, idx) => {
                    return (<Pagination.Item key={number} active={number == activePage} onClick={paginationClick}>
                        {number}
                    </Pagination.Item>)
                })
            )}
        </Pagination>
    )
}

export default PaginationCustom