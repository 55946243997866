import { Outlet } from "react-router-dom";
import Header from "./Header";
import MoreOptionsMenu from "./MoreOptionsMenu";

const Home = () => {

    const navOptions = [
        {
            name: 'New Order',
            to: '/'
        },{
            name: 'Orders',
            to: 'orders_in_queue'
        },{
            name: 'Payments List',
            to: 'payment_gateway_transactions'
        }
        // ,{
        //     name: 'Delivery Agents',
        //     dropDown: [
        //         {
        //             name: 'List of Delivery Agents',
        //             to: 'list_of_delivery_agents'
        //         }
        //         // , {
        //         //     name: 'Order Tracking',
        //         //     to: 'order_tracking'
        //         // }
        //     ]
        // }
        ,{
            name: 'Order Tracking',
            to: 'order_tracking'
        }
        ,{
            name: 'List of Delivery Agents',
            to: 'list_of_delivery_agents'
        }
    ]

    return (
        <>
            <Header />
            <MoreOptionsMenu name='Filter Options' scroll={true} backdrop={true} items={navOptions} />
            <Outlet />
        </>
    )
}

export default Home