import React, { useCallback, useContext, useEffect, useState } from 'react'
import { InputGroup, Form, Button, Table } from 'react-bootstrap'
import { getAxios, postAxios } from '../../Axios'
import request from '../../Requests'
import LoginContext from '../../store/login-context'
import { toast } from "react-toastify"
import PlaceOrderModal from './PlaceOrderModal'

function Dashboard() {
    const loginCtx = useContext(LoginContext)
    const [productId, setProductId] = useState('')
    const [show, setShow] = useState(false);
    const [products, setProducts] = useState([])
    const [totalPrice, setTotalPrice] = useState('')
    const [storeName, setStoreName] = useState('')
    const [storeID, setStoreId] = useState('')
    const [partyId, setPartyId] = useState('')
    const [successfullyData, setSuccessfullyData] = useState([])
    const [cartStatus, setCartStatus] = useState(false)
    const [customerPhone, setCustomerPhone] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [customerPartyId, setCustomerPartyId] = useState('')
    const [orderPayload, setOrderPayload] = useState([])

    useEffect(() => {
        loginCtx.whoAmi()
        if (!!loginCtx.storeDetails.stores) {
            setPartyId(loginCtx.storeDetails?.userinfo?.party_id)
            setStoreName(loginCtx.storeDetails.stores[0].store_name)
            setStoreId(loginCtx.storeDetails.stores[0].product_store_id)
        }
    }, [])
    const setProductIdState = (e) => {
        setProductId(e.target.value)
        if (e.target.value.length >= 10) {
            getProductDetails(e.target.value)
        }
    }
    function getTotal(total, object) {
        return total + object?.product_price?.discounted_price * object?.quantity

    }
    const getProductDetails = async (e) => {
        const toastId = toast.loading("Please wait...", {
            toastId: "barcodeToast"
        })
        const res = await getAxios(`${request.product_details}package_barcode=${e}&product_store_id=${storeID}`)

        if (res.data.status == 'OK') {
            setProductId('')
            if (products.length == 0) {
                res.data.Result.quantity = 1
                setProducts([res.data.Result])
            } else {
                const data = products.filter((d) => d.product_id == res.data.Result.product_id)
                const data2 = products.filter((d) => d.product_id != res.data.Result.product_id)

                if (!!data.length) {
                    data[0].quantity = data[0].quantity + 1
                    setProducts([...data2, data[0]])
                } else {
                    res.data.Result.quantity = 1
                    setProducts([...products, res.data.Result])
                }
            }
            toast.dismiss(toastId)
        } else {
            toast.dismiss(toastId)
            toast.error('Barcode not found', {
                toastId: 'error',
                autoClose: 1500
            })
        }
    }
    /* Preparing payload for order placement */
    const prepareOrderBasket = () => {
        for (let value of products) {
            //Making value key for payload
            const value_for_order = {
                atp: '15',
                attr_MAXOQ: value?.attr_MAXOQ,
                attr_MOQ: value?.attr_MOQ,
                available: value?.product_active,
                category_name: "",
                city: "",
                contact_mech_id: "",
                detail_image_url: value?.detail_image_url,
                large_image_url: value?.large_image_url,
                long_description: "",
                medium_image_url: value?.medium_image_url,
                order_quantity: value?.quantity,
                original_image_url: value?.detail_image_url,
                party_id: customerPartyId,
                person_name: customerName,
                price: {
                    discount_pct: value?.product_price?.discount_pct,
                    discounted_price: value?.product_price?.discounted_price,
                    price_off: value?.product_price?.price_off,
                    product_price: value?.product_price?.product_price,
                    product_price_currency: value?.product_price?.product_price_currency,
                    product_price_uom: value.product_price?.product_price_uom,
                    product_price_uom_abbrev: value?.product_price?.product_price_uom_abbrev
                },
                product_category_id: value?.primary_product_category_id,
                product_description: value?.product_description,
                product_id: value?.product_id,
                product_name: value?.product_name,
                product_price: value?.product_price?.product_price,
                qoh: '18',
                sku: "SKU_70000090_0010",
                small_image_url: value?.small_image_url,
                supplier_name: storeName,
                supplier_party_id: partyId,
                total_price: value?.product_price?.discounted_price * value?.quantity
            }
            // Value key completed
            const preparingOrder = {
                key: value.product_id,
                value: value_for_order
            }
            const notRepeatPayload = orderPayload.filter((value) => value.key != preparingOrder.key)
            setOrderPayload([...notRepeatPayload, preparingOrder])
        }
    }
    useEffect(() => {
        prepareOrderBasket()
        products.length > 0 && setTotalPrice(products.reduce(getTotal, 0))
    }, [products])
    const setCustomerNameState = (e) => {
        setCustomerName(e.target.value)
    }
    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 1000);
        };
    };
    const handleChange = async (value) => {
        const res = await getAxios(`${request.lookup_user}${value}`)
        if (res.data.TotalRecordCount > 0) {
            setCustomerName(res.data?.Records[0]?.full_name)
            setCustomerPartyId(res.data?.Records[0]?.party_id)
        } else {
            if (value.length >= 10) {
                toast.error("No User Found", {
                    autoClose: 1500,
                    toastId: 'error'
                })
                setCustomerPartyId("")
            }
        }
    };
    const optimizedFn = useCallback(debounce(handleChange), []);
    const placeOrder = async () => {

        if (orderPayload.length != 0) {

            const orderData = new FormData()
            orderData.append("basket_data", JSON.stringify(orderPayload))
            orderData.append("initiated_by", customerPartyId)
            const res = await postAxios(request.place_order, orderData)
            if (res.data.status == "OK") {
                setCustomerName('')
                setCustomerPhone('')
                setSuccessfullyData(res.data)
                setProducts('')
                setTotalPrice("")
                setShow(true)
                setCartStatus(false)
            } else {

            }
        } else {
            setCartStatus(true)
            setShow(true)
        }
    }
    const lookupForUser = () => {
        if (customerPhone.length >= 10 && customerPhone.length <= 12 && customerName.length != 0) {
            const [customer_fname, customer_lname] = customerName?.split(" ")
            const signupData = {
                fname: customer_fname,
                lname: customer_lname,
                country_code: "91",
                mobile: customerPhone,
                iam: "BUYER"
            }
            postAxios(request.signup_user, signupData).then((res) => {
                setCustomerPartyId(res?.data?.userinfo?.party_id)
            }
            ).then(placeOrder())
        } else {
            toast.error("Please Fill the Customer Details first", {
                autoClose: 1500,
                toastId: 'error'
            })
        }
    }

    function ProductTableBody({ value, sno }) {
        return (
            <tr>
                <td>{sno}</td>
                <td><img src={value?.small_image_url} style={{ width: '100%' }} /></td>
                <td>
                    {value?.product_name}
                    <br />
                    <span className='mt-1'><b>Product ID : </b>{value.product_id}</span>
                </td>
                <td>
                    {!!value.sku_number && value.sku_number}
                    {!value.sku_number && "Product SKU not found"}
                </td>
                <td>{value?.quantity}</td>
                <td className='text-end'>{value?.product_price.discounted_price + " " + value?.product_price?.product_price_currency}</td>
                <td className='text-end'>
                    {value?.product_price.discounted_price * value?.quantity + " " + value?.product_price.product_price_currency}
                </td>
            </tr>
        )
    }
    return (
            <section className='container-lg'>
                <div className="py-4">
                    <InputGroup className="mb-3">
                        <InputGroup.Text className='d-inline-block col-12 col-md-4'>Customer Details</InputGroup.Text>
                        <Form.Control aria-label="mobile-no" placeholder='Mobile No (Min. 10 digits)' value={customerPhone} onChange={(e) => {
                            setCustomerPhone(e.target.value)
                            optimizedFn(e.target.value)
                        }} />
                        <Form.Control aria-label="customer-name" placeholder='Customer Name' onChange={setCustomerNameState} value={customerName} />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Button variant="outline-secondary" id="add-product" >
                            Add Product
                        </Button>
                        <Form.Control
                            placeholder="Barcode Number"
                            aria-label="product-id"
                            aria-describedby="basic-addon2"
                            onChange={setProductIdState}
                            value={productId}
                        />
                    </InputGroup>

                </div>
                <div>
                    <PlaceOrderModal show={show} setShow={setShow} setSuccessfullyData={setSuccessfullyData} successfullyData={successfullyData} emptyCart={cartStatus} />
                    <div className="row justify-content-center justify-content-xl-between">
                        <div className="col-12 col-md-6">
                            <div className="d-flex justify-content-between justify-content-md-start w-100">
                                <Button variant="outline-success place-order" onClick={lookupForUser} className='mb-2 mb-md-0 me-0 me-md-3'>Place Order</Button>
                                <Button
                                    variant="outline-secondary place-order"
                                    onClick={() => {
                                        setProducts([])
                                        setOrderPayload([])
                                        setTotalPrice('')
                                    }}
                                    className='mb-2 mb-md-0'>
                                    Empty the Cart
                                </Button>
                            </div>

                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="inputGroup-sizing-default">
                                    Total
                                </InputGroup.Text>
                                <Form.Control
                                    aria-label="total"
                                    aria-describedby="inputGroup-sizing-default"
                                    value={totalPrice}
                                    style={{ fontSize: "30px" }}
                                    className="text-success"
                                    readOnly
                                />
                            </InputGroup></div>
                    </div>
                    <Table striped bordered hover className='mb-3' style={{
                        verticalAlign: 'middle',
                        textAlign: 'center'
                    }}>
                        <thead>
                            <tr>
                                <th style={{ width: '60px' }}>#</th>
                                <th style={{ width: '90px' }}>Image</th>
                                <th>Product Name</th>
                                <th>Product SKU No.</th>
                                <th style={{ width: '60px' }}>Quantity</th>
                                <th className='text-end'>Unit Price</th>
                                <th className='text-end'>Total Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.length > 0 && products?.map((value, index) => {
                                return (
                                    <ProductTableBody key={index} sno={index + 1} value={value} />
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </section>
    )
}

export default Dashboard
