import axios from "axios"

axios.defaults.withCredentials = true
const instance = axios.create({
    withCredentials: true
  })
  
const getAxios=async (url)=>{
    try{
        const res=await instance.get(url)
        return res
    }catch(err){

    }
}
const postAxios=async (url,data)=>{
    try{
        const res=await instance.post(url,data,{ headers: { "Content-Type": "multipart/form-data" } })
        return res
    }catch(err){

    }
}
export {getAxios,postAxios}