const BASE_URL="//haatnow.com/"
const request={
    login_user:BASE_URL+"webadmin/login/login_action",
    logoff_user:BASE_URL+"app_backend/signup/logoff",
    product_details:BASE_URL+"app_backend/api/ofbiz/catalog/get_product_by_barcode?",
    who_am_i:BASE_URL+"app_backend/signup/whoami",
    place_order:BASE_URL+'app_backend/paymentgateway/accept_pos',
    lookup_user:BASE_URL+"api/ofbiz/users/registered_user_list?login_id=91",
    signup_user:BASE_URL+"app_backend/signup/create",
    order_details:BASE_URL+"api/ofbiz/orders/order_list?&",
    payments_list:BASE_URL+"api/ofbiz/payments/payment_gateway_txn?&",
    track_order:BASE_URL+'api/ofbiz/users/registered_user_list?delivery_agents=1&',
    delivery_agents:BASE_URL+'api/ofbiz/users/registered_user_list?delivery_agents=1&'
}
export default request