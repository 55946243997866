import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getAxios } from '../../../Axios'
import request from '../../../Requests'
import PaginationCustom from '../../Commons/PaginationCustom'

function PaymentGatewayTransactions() {
  const [paymentsList, setPaymentsList] = useState([])
  const [activePage, setActivePage] = useState(['1'])

  const allPayments = () => {
    const id = toast.loading('Please Wait...', {
      toastId: 'loadingOrders'
    })
    setPaymentsList([])
    getAxios(`${request.payments_list}jtStartIndex=${(activePage - 1) * 30}&jtPageSize=10`).then((res) => {
      toast.dismiss()
      setPaymentsList(res.data)
    })
  }
  useEffect(() => {
    allPayments()
  }, [activePage])

  function ProductTableBody({ value }) {
    function isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }

    return (
      <tr>
        <td>{value?.txn_id}</td>
        <td>
          <b>Order By : </b>{value?.full_name}
          <br />
          <b>Order On : </b>{value?.created_date}
          <br />
          <b>Order Status : </b>{`${value?.order_status}`}
          <br />
          <b>Test Order : </b>{!!value?.test_entry ? 'Yes' : 'No'}
          <br />
          <b>Remarks : </b>{value?.system_remarks}
          <br />
          <b>Order Messages : </b>{value?.order_message}
          <br />
          <h1 className='mt-3' style={{ fontSize: '24px', color: '#198754' }}>Order Details</h1>
          {!!isJsonString(value.basket_data) && (
            <Table striped bordered hover className='mb-3' style={{
              verticalAlign: 'middle',
              textAlign: 'start'
            }}>
              <thead>
                <tr>
                  <th>Product Details</th>
                  <th>Image</th>
                  <th>Supplier Details</th>
                  <th>Price Details</th>
                  <th>Qty</th>
                  <th>Final Price</th>
                </tr>
              </thead>
              <tbody>
                {JSON.parse(value.basket_data).map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <b>{item.value.product_name}</b>
                        <br />
                        <b>SKU : </b>{item.value.sku}
                        <br />
                        <b>Product ID : </b>{item.key}
                      </td>
                      <td><img style={{ height: '200px' }} src={item.value.small_image_url} /></td>
                      <td>
                        <b>Supplier Name : </b>{item.value.supplier_name}
                        <br />
                        <b>Supplier ID : </b>{item.value.supplier_party_id}
                      </td>
                      <td>
                        <b>MRP : </b>{!!item.value?.price ? item.value?.price.product_price : item.value?.product_price}
                        <br />
                        <b>Discounted Price : </b>{!!item.value?.price ? item.value?.price.discounted_price : item.value?.discounted_price}
                        <br />
                        <b>Discount : </b>{!!item.value?.price ? item.value?.price.price_off : item.value?.price_off}
                      </td>
                      <td>{item.value.order_quantity}</td>
                      <td>{item.value.total_price}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}
          {!isJsonString(value.basket_data) && (
            <h1 style={{
              fontSize: '20px',
              color: 'red'
            }}>The order details received are not valid.</h1>
          )}
        </td>
      </tr>
    )
  }
  return (
    <div className='px-5 py-4'>
      <h1 style={{ fontSize: '30px' }}>Recent Transactions</h1>
      <div style={{overflowX:'scroll'}}>
        <Table striped bordered hover className='mb-3' style={{
          verticalAlign: 'middle',
          textAlign: 'start'
        }}>
          <thead>
            <tr>
              <th style={{ width: '100px' }}>Txn ID</th>
              <th>Order Details</th>
            </tr>
          </thead>
          <tbody>
            {!!paymentsList?.Records && paymentsList?.Records?.map((value, index) => {
              return (
                <ProductTableBody key={index} value={value} />
              )
            })}
          </tbody>
        </Table>
      </div>
      <PaginationCustom activePage={activePage} setActivePage={setActivePage} totalRecords={paymentsList.TotalRecordCount} />
    </div>
  )
}

export default PaymentGatewayTransactions