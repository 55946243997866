import React, { useCallback, useEffect, useState } from 'react'
import { InputGroup } from 'react-bootstrap'
import { Dropdown } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getAxios } from '../../../Axios'
import request from '../../../Requests'
import PaginationCustom from '../../Commons/PaginationCustom'
import moment from 'moment/moment'

const OrdersInQueue = () => {
    const [orderRecords, setOrderRecords] = useState({})
    const [activePage, setActivePage] = useState('1');
    const [searchOrderId, setSearchOrderId] = useState('')

    const totalOrders = useCallback(() => {
        const id = toast.loading('Please Wait...', {
            toastId: 'loadingOrders'
        })
        setOrderRecords([])
        getAxios(`${request.order_details}jtStartIndex=${(activePage - 1) * 10}&jtPageSize=10`).then((res) => {
            toast.dismiss()
            setOrderRecords(res.data)
        })
    }, [activePage])
    const searchOrders = (e) => {
        e.preventDefault()
        getAxios(`${request.order_details}jtStartIndex=${(activePage - 1) * 10}&jtPageSize=10&order_status=&from_date=&to_date=&order_id=${searchOrderId}`).then((res) => {
            toast.dismiss()
            setOrderRecords(res.data)
        })
        console.log('hello')
    }
    useEffect(() => {
        totalOrders()
    }, [activePage])

    function ProductTableBody({ value, idx }) {
        return (
            <tr>
                <td>{((activePage - 1) * 10) + idx + 1}</td>
                <td>{value?.order_id}</td>
                <td>{moment(value?.order_date*1000).format("LLL")}
                <br/>({moment(value?.order_date*1000).startOf('hour').fromNow()}); 
                </td>
                <td>
                    <b>{value?.customer_name}</b>
                    <br />
                    <span className='mt-1'>{value?.address}</span>
                    <br />
                    {/* <b>Delivery Status : </b>{value?.delivery_status} */}
                </td>
                <td className='text-center'>{value?.paymentGatewayTxn?.order_status}<br/>{value?.delivery_status}</td>

                <td>
                    <b>Txn ID : </b>{value?.paymentGatewayTxn?.txn_id}
                    <br />
                    <b>Amount Paid : </b>{value?.paymentGatewayTxn?.pg_fees}
                    <br />
                    <b>Grand Total : </b>{value?.grand_total}
                    <br />
                    <b>Payment Mode : </b>{value?.paymentGatewayTxn?.pg_id}
                </td>
                
                <td className='text-end'>
                    {value?.remaining_sub_total}
                </td>
                {/* <td>
                    <b>Supplier Name : </b>{value?.supplier_name}
                    <br />
                    <b>Supplier Address : </b>{value?.supplier_address}
                    <br />
                    <b>Supplier Phone : </b>{value?.supplier_phone}
                </td> */}

            </tr>
        )
    }
    return (
        <div className='px-5 py-4' style={{ overflow: 'hidden' }}>
            {/* <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Dropdown Button
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item>Action</Dropdown.Item>
                    <Dropdown.Item>Another action</Dropdown.Item>
                    <Dropdown.Item>Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown> */}
            <Form onSubmit={searchOrders}>
            <InputGroup className="mb-3">
                <Button variant="outline-secondary" type='submit'>
                    Button
                </Button>
                <Form.Control
                    aria-label="Example text with button addon"
                    aria-describedby="basic-addon1"
                    // value={searchOrderId}
                    onChange={e=>setSearchOrderId(e.target.value)}
                />
            </InputGroup>
            </Form>
            <h1 style={{ fontSize: '30px' }}>Orders in Queue</h1>
            <div style={{ overflowX: 'scroll' }}>
                <Table striped bordered hover className='mb-3' style={{
                    verticalAlign: 'middle',
                    textAlign: 'center'
                }}>
                    <thead>
                        <tr>
                            <th>Sno.</th>
                            <th>OrderID</th>
                            <th>Order Date</th>
                            <th>Customer Details</th>
                            <th className='text-start'>Order Status/<br/>Delivery Status</th>

                            <th>Payment Details</th>
                            {/* <th>Supplier Details</th> */}
                            <th className='text-end'>Remaining Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!!orderRecords?.Records && orderRecords?.Records?.map((value, index) => {
                            return (
                                <ProductTableBody key={index} value={value} idx={index} />
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            {orderRecords.TotalRecordCount != 0 && <PaginationCustom activePage={activePage} setActivePage={setActivePage} totalRecords={orderRecords.TotalRecordCount} />}
        </div>
    )
}

export default OrdersInQueue